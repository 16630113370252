<template>
  <v-container>
    <v-card v-if="team.t_gamla_rallyt" elevation="2" class="mx-auto my-12" max-width="400">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Nö(r)dkontakter</v-toolbar-title>
      </v-toolbar>
      <v-card-text align="left">
        <div class="black--text ms-4">
          Vid frågor kring Rebusrallyt eller vid olycka, kontakta
          <b>RallyChefen</b>
          <br><v-icon>mdi-phone</v-icon> <a href="tel:026129430">026 - 129 43 0</a>
        </div>
        <br>
        <div class="black--text ms-4">
          Vid problem med rallyboten, kontakta <b>NätSäk</b>
          <br><v-icon>mdi-phone</v-icon> <a href="tel:018258588">018 - 25 85 88</a>   
        </div>
        <br>
        <div class="black--text ms-4">
          Vid funderingar kring rebusar eller banan, lös det :)
        </div>
        <br>
        <div class="black--text ms-4">
          Vid ensamhet, kontakta <b>NätSeX</b>
          <br><v-icon>mdi-phone</v-icon> <a href="tel:18004778191">1-800-477-8191 </a> 
        </div>
        <br>
        <!-- TODO: Fixa användarnamn så man kan länka till messenger -->
        <div class="black--text ms-4">
          Vid övriga frågor kan ni kontakta Rallykå på messenger genom
          att klicka <a href="http://m.me/RebusrallyUtn">här</a>.
        </div>
      </v-card-text>
    </v-card>
    <v-card v-else elevation="2" class="mx-auto my-12" max-width="400">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Nö(r)dkontakter</v-toolbar-title>
      </v-toolbar>
        <v-card-text align="left">
        <div class="black--text ms-4">
          Vid frågor kring Rebusrallyt eller vid olycka, kontakta
          <b>RallyChefen</b>
          <br><v-icon>mdi-phone</v-icon> <a href="tel:0703145396">070 - 31 45 396</a>
        </div>
        <br>
        <div class="black--text ms-4">
          Vid problem med rallyboten, kontakta <b>NätSäk</b>
          <br><v-icon>mdi-phone</v-icon> <a href="tel:0704473727">070 - 44 73 727</a>   
        </div>
        <br>
        <div class="black--text ms-4">
          Vid funderingar kring rebusar eller banan, kontakta <b>ReBuS</b>
          <br><v-icon>mdi-phone</v-icon> <a href="tel:0705144505">070 - 51 44 505 </a> 
        </div>
        <br>
        <!-- TODO: Fixa användarnamn så man kan länka till messenger -->
        <div class="black--text ms-4">
          Vid övriga frågor kan ni kontakta Rallykå på messenger genom
          att klicka <a href="http://m.me/RebusrallyUtn">här</a>.
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'Contact',
  data: () => ({
    team: null
  }),
  methods: {
    ...mapActions(['getTeam']),
    async getUserTeam() {
      const team = await this.getTeam()
      this.team = team
    },
  },
  mounted() {
    this.getUserTeam()
  },
}
</script>

<style>

</style>